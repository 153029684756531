<template>
  <section class="section advantages">
    <h2>
      {{
        $t("{brand} advantages", {
          brand: brandWithCapitalizeFirstLetter,
        })
      }}
    </h2>

    <div class="advantages-container">
      <div class="advantages-select-tab">
        <SelectTab
          :options="SELECT_TAB_OPTIONS"
          :value="currentUser"
          @select="changeUser"
        >
          <template #option="slotProps">
            {{ $t(slotProps.option.value) }}
          </template>
        </SelectTab>
      </div>

      <div
        class="advantages-tab advantages-tab-user"
        :class="{ 'advantages-tab_hidden': currentUser !== User.members }"
      >
        <h3>{{ $t("Пользователям") }}</h3>
        <div class="advantages-content">
          <div class="advantages-images">
            <ScrollSlider
              :items="<IScrollSliderItem[]>$tm(`advantages_section.${User.members}.slider.images`)"
            >
              <template #scroll-slider-item="{ item }">
                <PictureComponent
                  :modern-format-image="{
                    'desktop-path': $rt(
                      item.item.image['modern-format-image']['desktop-path']
                    ),
                    'desktop-path-retina': $rt(
                      item.item.image['modern-format-image'][
                        'desktop-path-retina'
                      ]
                    ),
                  }"
                  :fallback-format-image="{
                    'desktop-path': $rt(
                      item.item.image['fallback-format-image']['desktop-path']
                    ),
                    'desktop-path-retina': $rt(
                      item.item.image['fallback-format-image'][
                        'desktop-path-retina'
                      ]
                    ),
                  }"
                  loading="lazy"
                />
              </template>
            </ScrollSlider>

            <PictureComponent
              :modern-format-image="{
                'desktop-path': '/image/advantagesSection/user.webp',
                'desktop-path-retina': '/image/advantagesSection/user@2x.webp',
              }"
              :fallback-format-image="{
                'desktop-path': '/image/advantagesSection/user.png',
                'desktop-path-retina': '/image/advantagesSection/user@2x.png',
              }"
              loading="lazy"
            />
          </div>
          <div class="advantages-text">
            <h3>
              {{ $t(`advantages_section.${User.members}.title`) }}
            </h3>
            <ul>
              <li
                v-for="item in $tm(
                  `advantages_section.${User.members}.advantages_list`
                )"
                :key="$rt(item)"
              >
                <BaseIcon icon="listPoint" height="20" width="20" />
                <span>
                  {{ $rt(item) }}
                </span>
              </li>
            </ul>
            <div class="advantages-links">
              <LocLink to="/facilities">
                <span>{{ $t("Facility list") }}</span>
                <BaseIcon icon="arrow" height="24" width="24" />
              </LocLink>
              <BaseButton text @click="showAskQuestionModal">{{
                $t("Ask Us Anything")
              }}</BaseButton>
            </div>
          </div>
        </div>
      </div>

      <div
        class="advantages-tab advantages-tab-company"
        :class="{ 'advantages-tab_hidden': currentUser !== User.companies }"
      >
        <h3>{{ $t("Компаниям") }}</h3>
        <div class="advantages-content">
          <div class="advantages-images">
            <PictureComponent
              :modern-format-image="{
                'mobile-path': '/image/advantagesSection/company-mob.webp',
                'mobile-path-retina':
                  '/image/advantagesSection/company-mob@2x.webp',
                'desktop-path': '/image/advantagesSection/company.webp',
                'desktop-path-retina':
                  '/image/advantagesSection/company@2x.webp',
              }"
              :fallback-format-image="{
                'mobile-path': '/image/advantagesSection/company-mob.png',
                'mobile-path-retina':
                  '/image/advantagesSection/company-mob@2x.png',
                'desktop-path': '/image/advantagesSection/company.png',
                'desktop-path-retina':
                  '/image/advantagesSection/company@2x.png',
              }"
              loading="lazy"
            />
          </div>
          <div class="advantages-text">
            <h3>
              {{ $t(`advantages_section.${User.companies}.title`) }}
            </h3>
            <ul>
              <li
                v-for="item in $tm(
                  `advantages_section.${User.companies}.advantages_list`
                )"
                :key="$rt(item)"
              >
                <BaseIcon icon="listPoint" height="20" width="20" />
                <span>
                  {{ $rt(item) }}
                </span>
              </li>
            </ul>
            <div class="advantages-links">
              <LocLink to="/companies">
                <span>{{ $t("Для компаний") }}</span>
                <BaseIcon icon="arrow" height="24" width="24" />
              </LocLink>
              <BaseButton
                text
                @click="
                  showAskGetAnOfferModal(CooperationTypes.CONNECT_COMPANY)
                "
                >{{ $t("Get an Offer") }}</BaseButton
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="advantages-tab advantages-tab-partner"
        :class="{ 'advantages-tab_hidden': currentUser !== User.partners }"
      >
        <h3>{{ $t("Партнерам") }}</h3>
        <div class="advantages-content">
          <div class="advantages-images">
            <PictureComponent
              :modern-format-image="{
                'mobile-path': '/image/advantagesSection/partner-mob.webp',
                'mobile-path-retina':
                  '/image/advantagesSection/partner-mob@2x.webp',
                'desktop-path': '/image/advantagesSection/partner.webp',
                'desktop-path-retina':
                  '/image/advantagesSection/partner@2x.webp',
              }"
              :fallback-format-image="{
                'mobile-path': '/image/advantagesSection/partner-mob.png',
                'mobile-path-retina':
                  '/image/advantagesSection/partner-mob@2x.png',
                'desktop-path': '/image/advantagesSection/partner.png',
                'desktop-path-retina':
                  '/image/advantagesSection/partner@2x.png',
              }"
              loading="lazy"
            />
          </div>
          <div class="advantages-text">
            <h3>
              {{ $t(`advantages_section.${User.partners}.title`) }}
            </h3>
            <ul>
              <li
                v-for="item in $tm(
                  `advantages_section.${User.partners}.advantages_list`
                )"
                :key="$rt(item)"
              >
                <BaseIcon icon="listPoint" height="20" width="20" />
                <span>
                  {{ $rt(item) }}
                </span>
              </li>
            </ul>
            <div class="advantages-links">
              <LocLink to="/partners">
                <span>{{ $t("Для партнеров") }}</span>
                <BaseIcon icon="arrow" height="24" width="24" />
              </LocLink>
              <BaseButton
                text
                @click="showAskGetAnOfferModal(CooperationTypes.PARTNER)"
                >{{ $t("Get an Offer") }}</BaseButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <LazyModalsAskQuestionFormModal
      v-if="isShowAskQuestionModal"
      v-model:is-show-modal="isShowAskQuestionModal"
    />
    <LazyModalsGetAnOfferFormModal
      v-if="isShowGetAnOfferModal"
      v-model:is-show-modal="isShowGetAnOfferModal"
      :cooperation-type="cooperationTypeForModal"
    />
  </section>
</template>

<script lang="ts">
import { CooperationTypes, User } from "~/types/entities";

import ScrollSlider, {
  IScrollSliderItem,
} from "~/components/slider/ScrollSlider.vue";
import BaseButton from "~/components/button/BaseButton.vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";
import SelectTab from "~/components/input/SelectTab.vue";
import LocLink from "~/components/lang/LocLink.vue";
import { AdvantagesEvents, SuggestionEvents } from "~/utils/analytics";

const SELECT_TAB_OPTIONS = [
  { value: "Пользователям", id: User.members },
  { value: "Компаниям", id: User.companies },
  { value: "Партнерам", id: User.partners },
];

export default defineNuxtComponent({
  components: {
    LocLink,
    SelectTab,
    ScrollSlider,
    BaseIcon,
    BaseButton,
  },
  setup() {
    const { gtag } = useGtag();
    const { brandWithCapitalizeFirstLetter } = useRuntimeConfig().public;
    const isShowAskQuestionModal = ref<boolean>(false);
    const isShowGetAnOfferModal = ref<boolean>(false);
    const cooperationTypeForModal = ref<CooperationTypes | null>(null);

    const currentUser = ref<User>(SELECT_TAB_OPTIONS[0].id);
    function changeUser(user: User) {
      currentUser.value = user;
      gtag("event", AdvantagesEvents[user]);
    }
    function showAskQuestionModal() {
      isShowAskQuestionModal.value = true;
      gtag("event", AdvantagesEvents.askQuestionModal);
    }
    function showAskGetAnOfferModal(cooperationType: CooperationTypes) {
      cooperationTypeForModal.value = cooperationType;
      isShowGetAnOfferModal.value = true;
      gtag("event", AdvantagesEvents.getOfferModal);
    }

    return {
      isShowAskQuestionModal,
      isShowGetAnOfferModal,
      cooperationTypeForModal,
      brandWithCapitalizeFirstLetter,
      currentUser,
      changeUser,
      showAskQuestionModal,
      showAskGetAnOfferModal,
      User,
      CooperationTypes,
      SELECT_TAB_OPTIONS,
    };
  },
});
</script>
